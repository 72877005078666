<!--
 * @Description: 新增、修改类目
 * @Author: 琢磨先生
 * @Date: 2022-05-09 11:02:42
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-14 11:31:27
-->
<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    direction="rtl"
    size="600px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="上级" v-if="father && father.id > 0">
        {{ father.full_name }}
      </el-form-item>

      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
          :disabled="loading"
          :loading="loading"
          >确定</el-button
        >
        <el-button @click="cancelClick" :disabled="loading">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      loading: false,
      title: "编辑类目",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["father", "reload"],
  watch: {
    father: {
      handler() {
        if (this.father) {
          this.drawer = true;
          this.title = "新增类目";
          this.form={}
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.father) {
            this.form.father_id = this.father.id;
          }
          this.$http
            .post("/admin/v1/category/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload(res.data);
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (typeof this.reload == "function") {
        this.reload();
      }
    },
  },
};
</script>