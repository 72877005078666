<!--
 * @Description: 后端类目
 * @Author: 琢磨先生
 * @Date: 2022-05-09 11:02:36
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-01 16:27:59
-->
<template>
  <el-card class="box">
    <el-form ref="query" :model="query" :inline="true">
      <el-form-item label="关键字">
        <el-input v-model="query.q" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="query.is_stop"
          clearable
          placeholder=""
          style="width: 120px"
        >
          <el-option label="正常" :value="false"></el-option>
          <el-option label="停用" :value="true"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="等级">
        <el-select
          v-model="query.level"
          clearable
          placeholder=""
          style="width: 120px"
        >
          <el-option label="一级" :value="1"></el-option>
          <el-option label="二级" :value="2"></el-option>
          <el-option label="三级" :value="3"></el-option>
          <el-option label="四级" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div>
      <el-button type="primary" size="small" @click="onAdd" round icon="plus"
        >新增顶级</el-button
      >
    </div>
  </el-card>
  <el-card class="box data">
    <div class="custom">
      <el-row style="margin-bottom: 10px;height: 24px;">
        <el-page-header v-if="currentFather"
          icon="ArrowLeft"
          :content="currentFather ? currentFather.full_name : ''"
          @back="onFatherChange"
        />
      </el-row>
      <el-button
        type="success"
        size="small"
        round
        icon="plus"
        @click="addChild"
      >
        新增子集
      </el-button>
    </div>
    <el-table :data="tableData.data" v-loading="loading" @sort-change="onSort" border>
      <!-- <el-table-column prop="id" label="编号" width="100"> </el-table-column> -->
      <el-table-column label="等级" width="100">
        <template #default="scope"> {{ scope.row.level }}级 </template>
      </el-table-column>
      <el-table-column prop="full_name" label="类目名称" sortable min-width="300">
      </el-table-column>
      <el-table-column prop="name" label="子叶节点" width="100">
        <template #default="scope">
          {{ scope.row.is_leaf ? "是" : "" }}
        </template>
      </el-table-column>
      <el-table-column label="属性设置" width="100">
        <template #default="scope">
          {{ scope.row.property_group_count }}组
          {{ scope.row.property_count }}个
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag
            :type="scope.row.is_stop ? 'danger' : 'success'"
            size="small"
            >{{ scope.row.is_stop ? "停用" : "正常" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="create_at" label="创建时间" width="180">
      </el-table-column>
      <el-table-column label="操作" width="220" fixed="right">
        <template #default="scope">
          <el-button
            type="primary" link
            size="small"
            @click="onLowerLevel(scope.row)"
            v-if="scope.row.level <= 3"
            >查看子集</el-button
          >
          <el-button type="primary" link size="small" @click="setProperty(scope.row)"
            >属性设置</el-button
          >
          <el-popconfirm
            v-if="!scope.row.is_super"
            title="您确定要删除？"
            @confirm="onDelete(1, scope.row)"
          >
            <template #reference>
              <el-button size="small" type="danger" link class="btn-del"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    >
    </el-pagination>
  </el-card>
  <edit-category :reload="reload" :father="father"></edit-category>
  <set-property :category="current" :cancel="(current = null)"></set-property>
</template>
<script>
import EditCategory from "./edit_category.vue";
import SetProperty from "./set_property.vue";
export default {
  components: {
    EditCategory,
    SetProperty,
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
        is_stop: "",
        father_id: "",
      },
      //添加使用
      father: null,
      //设置属性使用
      current: null,
      /**
       * 列表数据
       */
      tableData: {
        counts: 0,
      },
      //当前父级
      currentFather: null,
      //历史父级集合
      fatherList: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 查询搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData(refresh) {
      this.loading = true;
      if (refresh) {
        this.query.pageIndex = 1;
      }
      this.father = null;
      this.$http.post("/admin/v1/category", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 后退
     */
    onFatherChange() {
      if (this.fatherList.length > 0) {
        this.fatherList.pop();
        this.currentFather = this.fatherList[this.fatherList.length - 1];
      } else {
        this.currentFather = null;
      }
      console.log(this.currentFather);
      this.query.father_id = this.currentFather ? this.currentFather.id : "";
      this.loadData(true);
    },
    /**
     * 查看下级
     */
    onLowerLevel(item) {
      this.query.q = "";
      this.fatherList.push(item);
      this.currentFather = item;
      this.query.father_id = item.id;
      this.loadData(true);
    },
    /**
     *  新增、最上级
     */
    onAdd() {
      this.father = {};
    },
    /**
     *  新增子级
     */
    addChild() {
      if (this.fatherList.length > 0) {
        this.father = this.fatherList[this.fatherList.length - 1];
        var temp = "";
        for (let index = 0; index < this.fatherList.length; index++) {
          var x = this.fatherList[index];
          if (index < this.fatherList.length - 1) {
            temp += `${x.name}>>`;
          } else {
            temp += x.name;
          }
        }
        this.father.full_name = temp;
      } else {
        this.father = {};
      }
    },

    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(act, item) {
      this.$http.get("/admin/v1/category/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          if (act == 1) {
            this.loadData();
          } else {
            this.$refs.tree.remove(item);
          }
        }
      });
    },
    /**
     * 新增回调
     */
    reload() {
      if (this.father && this.father.id > 0) {
        this.loadData(true);
      } else {
        this.onFatherChange();
      }
    },
    /**
     * 设置属性
     */
    setProperty(item) {
      console.log(item);
      this.current = item;
    },
  },
};
</script>

<style>
.custom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.custom .el-breadcrumb {
  padding-left: 0;
  padding-top: 0;
}
.custom .top {
  color: var(--theme-color);
  cursor: pointer;
}
.custom .father_inner .el-breadcrumb__inner {
  color: var(--theme-color);
  cursor: pointer;
}
</style>